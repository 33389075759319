import {
  Details,
  Image,
  //  Member,
  Name,
  NameSub,
  Position,
  //  ThreeCols,
  TwoCols,
} from "../components/atoms/TeamObjs";
import { WhiteBackLayer } from "../components/layout/BackgroundLayer";
import { SingleLayout } from "../components/layout/SingleLayout";

export const TeamBackLayer = () => {
  return null;
};

export const TeamPage = () => {
  return (
    <div>
      <WhiteBackLayer />

      <SingleLayout maxWidth="lg">
        <h1 className="text-xl text-center font-sans italic text-rose-700 ">
          Team
        </h1>

        <div className="mt-12 md:mt-20">
          <TwoCols>
            <Image src="./images/team/shoda_ryo.jpg" alt="" />
            <div>
              <Name>Shoda Ryo</Name>
              <NameSub>庄田 竜</NameSub>
              <Position>株式会社庄田竜事務所 代表取締役社長</Position>
              <Details>
                <p>人々のライフスタイルに衝撃を。</p>
                <p>
                  講演実績として東京大学/京都大学/神戸大学/法政大学/関西学院大学
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/fukuda_yukiko.jpg" alt="" />
            <div>
              <Name>Fukuda Yukiko</Name>
              <NameSub>福田有紀子</NameSub>
              <Details>
                <Position>Back Office</Position>
                <p></p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/furuta.jpg?20230728" alt="" />
            <div>
              <Name>Furuta Hiroyuki</Name>
              <NameSub>古田寛幸</NameSub>
              <Details>
                <Position>Jリーグセカンドキャリア事業 責任者</Position>
                <p>
                  元プロサッカー選手。J1,J2,J3全てのカテゴリーでプレー。現役アスリートが参加するキャリア支援を担当。
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/uno.jpg" alt="" />
            <div>
              <Name>Uno Shintaro</Name>
              <NameSub>宇野晋太郎</NameSub>
              <Details>
                {/* <Position></Position> */}
                <p></p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/shintani_izumi.jpg" alt="" />
            <div>
              <Name>Shintani Izumi</Name>
              <NameSub>新谷 和実</NameSub>
              <Details>
                <Position>
                  NPO法人The Peace Front 代表理事
                  <br />
                  株式会社代表The Peace Front 代表取締役
                </Position>
                <p>
                  理念：より良い社会を実現し続ける
                  <br />
                  ビジョン：社会課題解決の量と質を高める 他<br />
                  東京大学大学院生（インパクト評価研究）
                  <br />
                  途上国向け行政プロジェクト参画
                  <br />
                  大学連合事務局員（産官学民連携）
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/kugimiya.jpg" alt="" />
            <div>
              <Name>Kugimiya Mitsumasa</Name>
              <NameSub>釘宮光政</NameSub>
              <Details>
                <Position>会計士</Position>
                <p></p>
              </Details>
            </div>
          </TwoCols>
        </div>

        {/* <div className="mt-12 md:mt-20">
          <ThreeCols>
            <Member
              imgSrc="./images/team/ph150x150.png"
              job="Web Developer"
              name="その他メンバー"
            />
          </ThreeCols>
        </div> */}
      </SingleLayout>
    </div>
  );
};
